@use "~styles/global/_color.scss" as *;
                  @use "~styles/global/_variable.scss" as *;
                  @use "~styles/global/_mixin.scss" as *;
@import "~styles/components/_button.module";
@import "~styles/components/_searchInput.module";

.relevancePage {
  .searchWrapper {
    display: block;
    position: relative;
    z-index: 999;
    margin: 0 auto 28px auto;
    @include searchFormSpec(680px, 52px);
  }
  width: 100%;
  max-width: 1350px;
  margin: 70px auto 0 auto;
  padding: 68px 136px 70px 136px;
  .relevancePageInner {
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    .topWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;
      .searchResult {
        width: min(700px, 100%);
        font-size: $f16;
        font-weight: 300;
        color: $gray500;
        i {
          font-size: $f20;
          font-weight: $fw500;
          line-height: $lh24;
          color: $gray900;
          word-wrap: break-word;
          margin-left: 6px;
        }
      }
      .sortItem {
        display: flex;
        align-items: center;
        > span {
          font-size: 11px;
          font-weight: $fw300;
          color: $gray300;
          margin-right: 12px;
        }
      }
    }
    .relevanceContainer {
      padding: 8px;
      margin: 0;
    }
  }
}

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

@use "~styles/global/_color.scss" as *;
                  @use "~styles/global/_variable.scss" as *;
                  @use "~styles/global/_mixin.scss" as *;
@import "~styles/components/_videoSectionColorSystem";
@import "~styles/components/_common.module";

.resultNotFoundPage {
  width: 100%;
  height: calc(100vh - 416px);
  .notFoundPageInner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .imgBox {
      width: 238px;
      height: 175px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .content {
      margin-top: 40px;
      > div {
        color: $gray300;
        text-align: center;
        &.title {
          font-size: $f20;
        }
        &.subTitle {
          font-size: $f16;
        }
      }
    }
  }
}

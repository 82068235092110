@use "~styles/global/_color.scss" as *;
                  @use "~styles/global/_variable.scss" as *;
                  @use "~styles/global/_mixin.scss" as *;
.itemContainer {
  background-color: #fff;
  box-shadow: 0px 1px 3px #00000029;
  border-radius: 5px;
  padding: 30px 60px;
  margin-bottom: 16px;
  overflow: hidden;
  &:last-child {
    margin-bottom: 0;
  }
}

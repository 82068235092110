@use "~styles/global/_color.scss" as *;
                  @use "~styles/global/_variable.scss" as *;
                  @use "~styles/global/_mixin.scss" as *;
@import "~styles/components/_common.module";
@import "~styles/browser/_breakpoint";
@import "~styles/components/_videoSectionColorSystem";

.annotationSectionMarkerWrapper {
  width: 100%;
  .controller {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    .resultText {
      font-size: $f14;
      color: $gray500;
    }
    .icon {
      @include icon(24px, 24px, $gray500);
      margin-left: 24px;
    }
  }
  .annotationSectionMarker {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 6px;
    margin: 26px auto 10px auto;
    border-radius: 3px;
    overflow: hidden;
    position: relative;
    .annotationPoint {
      position: absolute;
      width: 4px;
      height: 6px;
      background-color: $yellow500;
      border-radius: 1px;
      border: solid 1px #fff;
      cursor: pointer;
    }
    .annotationPointFocus {
      position: absolute;
      width: 6px;
      height: 6px;
      background-color: $yellow500;
      border-radius: 1px;
      border: solid 1px #fff;
    }
    @include videoSecColorSystem($opacity: false);
  }
  .annotationCard {
    display: flex;
    align-items: flex-start;
    height: 68px;
    padding: 10px 8px;
    border-radius: 10px;
    background-color: #fff;
    cursor: pointer;
  }
  .star {
    font-size: $f14;
    color: $yellow500;
    margin-right: 6px;
  }
  .annotationContent {
    font-size: $f12;
    line-height: $lh16;
    color: $gray900;
    font-weight: $fw300;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  .annotationCardWrapper {
    padding: 10px 0;
  }
}

@use "~styles/global/_color.scss" as *;
                  @use "~styles/global/_variable.scss" as *;
                  @use "~styles/global/_mixin.scss" as *;
@import "~styles/components/_videoSectionColorSystem";
@import "~styles/components/_common.module";

@mixin videoContainer($videoWidth: 240px) {
  display: grid;
  grid-template-columns: $videoWidth 1fr;
  column-gap: 32px;
  width: 100%;
  @content;
}

@mixin ItemImageSize($width: 100%, $height: 136px) {
  width: $width;
  height: $height;
}

.itemWrapper {
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
  .itemWrapperInner {
    @include videoContainer;
    .videoWrapper {
      @include ItemImageSize(100%, 136px);
      display: block;
      border-radius: 6px;
      overflow: hidden;
      .img {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding: 8px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        .videoTime {
          position: inherit;
          font-size: $f12;
          color: #fff;
          padding: 2px 4px;
          border-radius: 2px;
          background-color: rgba(0, 0, 0, 0.5);
        }
        .icon {
          @include icon($circle: true);
        }
      }
    }
    .contentWrapper {
      width: 100%;
      .itemType {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 12px;
        .TypeBox {
          display: flex;
          align-items: center;
          .icon {
            @include icon(13px, 13px, $blue500);
            margin-right: 10px;
          }
          .typeText {
            font-size: $f12;
            color: $blue500;
            font-weight: normal;
          }
        }
        .levelBox {
          display: flex;
          align-items: center;
          .icon {
            margin-right: 4px;
          }
          .levelText {
            font-size: $f12;
            color: $gray500;
          }
        }
      }
      .dataScience {
        width: min(670px, 100%);
        font-size: $f14;
        color: $gray500;
        font-weight: normal;

        margin-bottom: 12px;
        @include text-overflow(2);
      }
      .disc {
        display: -webkit-box;
        width: 100%;
        max-height: 56px;
        font-size: $h9;
        color: $gray500;
        line-height: $lh18;
        margin-bottom: 12px;
        @include text-overflow(2);
      }
      .author {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .publishedTime {
          font-size: $f12;
          color: $gray500;
          margin-left: 8px;
        }
      }
      .priceItem {
        font-size: $f16;
        color: $gray800;
        font-weight: $fw500;
      }
    }
  }
  .bottomWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-top: solid 1px $gray02;
    margin-top: 15px;
    padding-top: 15px;
    .controller {
      display: flex;
      align-items: center;
      cursor: pointer;
      .resultText {
        font-size: $f14;
        color: $gray500;
      }
      .icon {
        @include icon(24px, 24px, $gray500);
        margin-left: 36px;
      }
    }
    .itemData {
      display: flex;
      align-items: center;
      li {
        display: flex;
        align-items: center;
        margin-right: 45px;
        > div {
          font-size: $f12;
          color: $gray100;
          &.icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            width: 20px;
            height: 20px;
            position: relative;
            img {
              filter: invert(84%) sepia(0%) saturate(0%) hue-rotate(338deg)
                brightness(98%) contrast(90%);
            }
          }
        }
      }
    }
  }
  &.subResultItem {
    background-color: #fff;
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 5px;
    margin: 25px 0 16px 0;
    &:last-child {
      margin-bottom: 0;
    }
    .itemWrapperInner {
      padding: 20px 88px 20px 44px;
      @include videoContainer(196px);
      .videoWrapper {
        @include ItemImageSize(100%, 110px);
        .img {
          .icon {
            @include icon(24px, 24px, $circle: true);
            > span {
              font-size: 12px;
            }
          }
        }
      }
      .contentWrapper {
        .TypeBox {
          .icon {
            @include icon(12px, 12px, $gray500);
          }
          .typeText {
            color: $gray500;
          }
        }
        .levelBox {
          display: flex;
          align-items: center;
          .icon {
            margin-right: 4px;
          }
          .levelText {
            font-size: $f12;
            color: $gray500;
          }
        }
        section {
          margin-bottom: 20px;
          color: $gray500;
          font-weight: normal;
          &:last-child {
            margin-bottom: 0;
          }
          .secTitle {
            font-size: $f12;
            color: $gray300;
            margin-bottom: 8px;
          }
          &.covered {
            ul {
              li {
                font-size: $h9;
                list-style: disc;
                margin: 0 0 5px 15px;
              }
            }
          }
          &.tags {
            ul {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              column-gap: 8px;
              grid-template-rows: 1fr;
              row-gap: 8px;
              li {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                font-size: 13px;
                text-align: center;
                color: #727171;
                padding: 7px 16px;
                background-color: #efefef66;
                border-radius: 50px;
                em {
                  display: block;
                }
              }
            }
          }
          &.sectionCoveredItem {
            ul {
              @include videoSecColorSystem($opacity: true);
              li {
                width: fit-content;
                margin-bottom: 10px;
                padding: 5px 10px;
                border-radius: 6px;
                &:last-child {
                  margin-bottom: 0;
                }
                span {
                  font-size: $f12;
                  color: $gray100;
                  font-weight: 300;
                  margin-right: 10px;
                  &.keyWord {
                    font-size: $h9;
                    color: $gray800;
                  }
                  em {
                    font-weight: $fw500;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.annotationWrapper {
  width: 100%;
  background-color: $gray01;
  padding: 16px 44px;
}

@mixin buttonTemplate($width, $color, $backgroundColor, $fontSize: $f16) {
  width: $width;
  font-size: $fontSize !important;
  text-align: center;
  color: $color;
  background-color: $backgroundColor;
  border-radius: 2px;
  line-height: normal;
  padding: 10px 15px;
  @content;
  &:hover {
    background-color: $backgroundColor;
    opacity: 0.8;
    @content;
  }
}

@mixin nativeButtonTemplate(
  $maxWidth: 100%,
  $fontSize: $f14,
  $color: #fff,
  $backgroundColor: $blue500
) {
  width: 100%;
  max-width: $maxWidth;
  font-size: $fontSize;
  text-align: center;
  color: $color;
  background-color: $backgroundColor;
  border-radius: 2px;
  line-height: normal;
  outline: none;
  padding: 8px 20px;
  border: none;
  cursor: pointer;
  @content;
}

.btnLogin {
  @include buttonTemplate(100%, $blue500 !important, transparent);
}

// custom react-items-carousel arrow Icon button
.iconBox {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  margin: auto 0;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  > span {
    color: $gray500;
    font-size: $f24;
  }
  &.right {
    right: 20px;
  }
  &.left {
    left: 20px;
  }
}

.pageFabButton {
  margin: 16px;
  img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
  .loading {
    color: white;
  }
  span.buttonText {
    color: #fff;
    font-size: $f14;
  }
}

.backToTop {
  @include buttonTemplate(72px, #fff, rgba(0, 0, 0, 0.4)) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 72px;
    border-radius: 100%;
  }
  position: fixed;
  right: 24px;
  z-index: 999;
  bottom: 145px;
  cursor: pointer;
}

@mixin loadMoreTextButton($fontSize: $f14, $color: $blue500, $marginTop: 24px) {
  width: 100%;
  font-size: $fontSize;
  font-weight: $fw500;
  text-align: center;
  color: $color;
  margin-top: $marginTop;
  cursor: pointer;
}

@mixin downloadButton() {
  .download_btn_textBox {
    display: flex;
    align-items: center;
    padding: 10px 8px;
    cursor: pointer;
    width: fit-content;
    &:hover {
      border-radius: 4px;
      background-color: rgba(114, 113, 113, 0.04);
      transition: all 0.3s ease-in-out;
    }
    .icon {
      color: $gray500;
      margin-right: 5px;
    }
    &:disabled {
      .icon {
        color: $gray200;
      }
      .btn_text {
        color: $gray200;
      }
    }
  }
  .download_link {
    max-width: fit-content;
    cursor: pointer;
    font-size: 12px !important;
    line-height: 14px !important;
    color: $blue500 !important;
    padding: 0 !important;
  }
  .link_downloading {
    font-size: 12px;
    line-height: 14px;
  }
  .btn_downloading_Box {
    display: flex;
    align-items: center;
  }
  .btn_text {
    font-size: $f14;
    color: $gray500;
    font-weight: $fw500;
    &.downloading {
      margin-left: 10px;
      cursor: default;
    }
  }
  .group {
    .icon {
      color: $blue500;
    }
    .btn_text {
      color: $blue500;
    }
  }
}

@mixin floatingPublishButton() {
  position: fixed;
  bottom: 48px;
  left: min(1200px, 88%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: $gray100;
  padding: 12px 20px;
  outline: none;
  border: none;
  color: #fff;
  box-shadow: 1px 4px 5px rgba(0, 0, 0, 0.25);
  .icon_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  .button_text {
    font-size: $f14;
    font-weight: $fw500;
  }
  &.active {
    background-color: $blue500;
    cursor: pointer;
  }
}

.rwdButton {
  margin-left: 8px;
  .largeButton {
    color: white;
    display: flex;
    gap: 8px;
    @media (max-width: 1200px) {
      display: none;
    }
  }
  .smallButton {
    color: white;
    display: none;
    @media (max-width: 1200px) {
      display: flex;
      gap: 8px;
    }
  }
}

@mixin searchFormSpec($width, $height) {
  width: $width;
  height: $height;
  form {
    button {
      @if $height >= 50 {
        padding: $f14;
      } @else {
        padding: 6px;
      }
    }
  }
}

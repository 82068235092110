$transparentColor: "blue" rgba(0, 173, 239, 0.4),
  "green" rgba(143, 195, 81, 0.4), "purple" rgba(138, 116, 173, 0.4),
  "origin" rgba(255, 195, 7, 0.4), "navyBlue" rgba(60, 88, 200, 0.4);

$solidColor: "blue" $blue500, "green" $green500, "purple" $purple500,
  "origin" $yellow500, "navyBlue" $blue600;

@mixin videoSecColorSystem($opacity: false) {
  @each $className, $backgroundColor in $solidColor {
    .#{$className} {
      background-color: $backgroundColor;
    }
  }
  @if ($opacity) {
    @each $className, $backgroundColor in $transparentColor {
      .#{$className} {
        background-color: $backgroundColor;
      }
    }
  }
  @content;
}

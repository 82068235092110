@use "~styles/global/_color.scss" as *;
                  @use "~styles/global/_variable.scss" as *;
                  @use "~styles/global/_mixin.scss" as *;
@import "~styles/components/_button.module";
@import "~styles/browser/_breakpoint";
@import "~styles/components/_searchInput.module";
.explore {
  max-width: 1350px;
  min-width: 960px;
  margin: 32px auto 0;
  .academyLayout {
    width: 100%;
    padding: 20px 0 0;
    .academyLayoutInner {
      width: 100%;
      height: 100%;
      .categoriesWrapper {
        width: 100%;
        margin-bottom: 36px;
      }
      .cardWrapper {
        width: 100%;
        margin-bottom: 34px;
        &:last-child {
          margin-bottom: 0;
        }
        .secTitle {
          width: 100%;
          display: flex;
          align-items: baseline;
          justify-content: space-between;
          margin-bottom: 15px;
          h3 {
            font-size: $f24;
            color: $gray700;
            font-weight: $fw500;
            line-height: $f24;
          }
          .viewAllCard {
            text-transform: uppercase;
            font-size: $f14;
            color: $blue500;
            cursor: pointer;
          }
        }
        .cardWrapperInner {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          @include breakpoint(lg) {
            grid-template-columns: repeat(2, 1fr);
          }
          column-gap: 18px;
          row-gap: 20px;
          justify-content: center;
          padding: 0px 3px 6px;
        }
        .carouselCard {
          width: 100%;
          display: flex;
          column-gap: 18px;
          row-gap: 20px;
          justify-content: center;
          padding: 0px 6px 6px;
        }
      }
    }
  }
}
.searchWrapper {
  display: block;
  position: relative;
  z-index: 999;
  margin: 0 auto 28px auto;
  @include searchFormSpec(680px, 52px);
}

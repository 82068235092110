$purchase: "COMPLETED" $green500, "PROCESSING" $blue500;
@each $className, $backgroundColor in $purchase {
  .orderState#{$className} {
    font-size: $f14;
    text-align: center;
    color: #fff;
    padding: 3px 12px;
    border-radius: 50px;
    background-color: $backgroundColor;
  }
}

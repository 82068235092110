@mixin videoSize($height, $radius: false) {
  @if ($radius) {
    border-radius: 6px;
  }
  position: relative;
  width: 100%;
  height: $height;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @content;
  .videoTime {
    position: absolute;
    right: 8px;
    top: 8px;
    font-size: $f12;
    color: #fff;
    border-radius: 2px;
    padding: 2px 4px;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .iconType {
    position: absolute;
    right: 8px;
    bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    color: #fff;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

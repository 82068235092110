@use "~styles/global/_color.scss" as *;
                  @use "~styles/global/_variable.scss" as *;
                  @use "~styles/global/_mixin.scss" as *;
@import "~styles/components/_button.module";
@import "~styles/browser/_breakpoint";

.searchInputWrapper {
  position: relative;
  height: 100%;
  width: 100%;
  .suggestion {
    margin: 10px 0;
    position: absolute;
    background: white;
    width: 680px;
    z-index: 100;
    box-shadow: 0px 3px 6px 0px #00000029;
    border-radius: 6px;
    .suggestionList {
      display: flex;
    }
    div {
      display: flex;
      line-height: 24px;
      font-size: $f16;
      color: $gray500;
      align-items: center;
      cursor: pointer;
      height: 45px;
      width: 100%;
      padding: 0 10px;
      &.categoryTitle {
        font-size: $f14;
        margin-left: 27px;
      }
      &.trendingHover {
        &:hover {
          background-color: $gray02;
        }
      }
      &.focus {
        background-color: $gray02;
        &:hover {
          .close {
            display: block;
          }
        }
      }
      .history {
        margin: 0px 14px 0px 27px;
      }
      .trending {
        margin: 0px 14px 0px 27px;
        color: $gray500;
        background: $gray02;
        border-radius: 50%;
      }
      .text {
        width: 100%;

        @include text-overflow(1);
      }
      + .close {
        position: absolute;
        right: 0;
        display: none;
        &:hover {
          display: flex;
        }
      }
      &:hover {
        + .close {
          display: flex;
        }
      }
    }
  }
  .searchForm {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
  }
  .searchInput {
    width: 100%;
    height: 100%;
    min-height: 40px;
    padding: 0 60px;
    caret-color: $blue500;
    color: $gray500;
    border: none;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 4px #00000033;
    &::placeholder {
      color: $gray100;
    }
  }
  .searchIcon {
    position: absolute;
    left: 0;
    margin: 5px 0 5px 5px;
    &:hover {
      color: $blue500;
    }
  }
  .icon_close {
    position: absolute;
    right: 0;
    margin: 5px 5px 5px 0;
  }
}
